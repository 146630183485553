.loadingPage {
  height: 100vh;
  width: 100%;
  display: grid;
  place-items: center;
}

.loadingCircle {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  border: 10px solid #ffffff;
  border-top: 10px solid rgb(34, 34, 34);
  border-bottom: 10px solid rgb(32, 23, 23);
  animation: loading 1s linear infinite;
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
