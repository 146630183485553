.about {
  background-color: hsl(219, 48%, 8%);
  min-height: 100vh;
  display: flex;
}

.aboutContainer {
  background-color: rgb(255, 255, 255);
  width: 60%;
  height: 70vh;
  position: absolute;
  right: 0;

  display: flex;
  justify-content: right;
  padding: 2vmax;
  box-sizing: border-box;
}

.aboutContainer > p {
  /* text-align: right; */
  letter-spacing: 5px;
  /* word-spacing: 5px; */
  line-height: 60px;
  color: rgba(15, 15, 15, 0.822);
  font-size: 1.5vmax;
  width: 30%;
}

.aboutContainer2 {
  width: 60%;
  min-height: 90vh;
  background-color: white;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.281);
  position: absolute;
  bottom: 0;
  box-sizing: border-box;
  border-radius: 10px;
  /* padding: 2vmax; */
  display: flex;
  transform: translateX(-100vw) translateY(-10%) rotateZ(-360deg);

  animation: aboutAnimation 0s ease-in forwards;
}
.aboutContainer2 > div {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1vmax;
  color: rgba(17, 17, 17, 0.712);
  box-sizing: border-box;
}
.aboutContainer2 > div:first-child {
  align-items: center;
}

.aboutAvatar {
  width: 300px;
  height: 300px;
  border-radius: 100%;
  transition: all 0s;
}

.aboutAvatar:hover {
  transform: scale(1.05);
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.452); */
}

@keyframes aboutAnimation {
  to {
    transform: translateX(20%) translateY(-10%);
  }
}
@keyframes aboutAnimationPhone {
  to {
    transform: translateX(0%) translateY(0%);
  }
}
@media screen and (max-width: 600px) {
  .about {
    align-items: flex-end;
    padding-top: 100px;
  }
  .aboutContainer {
    display: none;
  }

  .aboutContainer2 {
    width: 100%;
    position: relative;
    flex-direction: column;
    animation: aboutAnimationPhone 1s ease-in forwards;
  }

  .aboutContainer2 > div:last-child > p {
    text-align: center !important;
  }

  .aboutAvatar {
    width: 100px;
    height: 100px;
    transform: translateY(-80%);
  }
}
